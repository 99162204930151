body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #131316;
  color: #f2f2f3;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "JetBrainsMono";
  font-style: normal;
  font-weight: 700;
  src: url("./fonts/JetBrainsMono/JetBrainsMono-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "JetBrainsMono";
  font-style: normal;
  font-weight: 300;
  src: url("./fonts/JetBrainsMono/JetBrainsMono-Light.woff2") format("woff2");
}

@font-face {
  font-family: "JetBrainsMono";
  font-style: italic;
  font-weight: 300;
  src: url("./fonts/JetBrainsMono/JetBrainsMono-LightItalic.woff2")
    format("woff2");
}
