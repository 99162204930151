.ingredients {
  width: 620px;
}

.ingredients__menuContainer {
  max-width: 100%;
  max-height: 70vh;
  overflow-y: scroll;
  overflow-x: unset;
}

.ingredients__menuContainer::-webkit-scrollbar {
  width: 8px;
}

.ingredients__menuContainer::-webkit-scrollbar-track {
  background: #2f2f37;
}

.ingredients__menuContainer::-webkit-scrollbar-thumb {
  background: #a5a5d6;
}

.ingredients__menuContainer::-webkit-scrollbar-thumb:hover {
  background: #8585ad;
}

.ingredients__list {
  list-style-type: none;
  display: flex;
  padding: 0;
  margin: 0;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
  flex-wrap: wrap;
}

.ingredients__basketList {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
