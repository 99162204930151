.notFound {
  margin: 200px auto 0;
  text-align: center;
  position: relative;
  z-index: 5;
}

.title {
  text-shadow: 0px 0px 16px rgb(51 51 255 / 25%),
    0px 0px 8px rgb(51 51 255 / 25%), 0px 4px 32px rgb(51 51 255 / 50%);
  font-size: 240px;
  line-height: 240px;
  margin: 0 0 10px 0;
}

.text {
  text-shadow: 0px 0px 16px rgb(51 51 255 / 25%),
    0px 0px 8px rgb(51 51 255 / 25%), 0px 4px 32px rgb(51 51 255 / 50%);
  font-size: 30px;
  margin: 0 0 60px 0;
}

.link {
  font-size: 30px;
  margin: 30px 0 0;
  text-decoration: none;
  color: #4c4cff;
  transition: 0.2s;
}

.link:hover {
  opacity: 0.7;
}

.img {
  position: absolute;
  opacity: 0.6;
  width: 60%;
  height: auto;
  left: -150px;
  top: 40%;
  z-index: 2;
}

.imgLoading {
  position: absolute;
  opacity: 0.2;
  width: 60%;
  height: auto;
  left: -185px;
  top: 18%;
  z-index: 1;
}
