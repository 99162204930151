.basket {
  width: 620px;
  height: 75vh;
  transition: 0.2s;
}

.basket__list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
  transition: 0.2s;
}

.basket__list_modified {
  composes: basket__list;
  transform: scale(1.05);
}

.basket__listItem {
  padding: 0;
}

.constructor-element__text {
  display: inline-block;
  max-height: 48px;
  max-width: 240px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.basket__listContainer {
  display: flex;
  flex-direction: column;
  max-height: 464px;
  overflow-y: scroll;
  width: 100%;
  align-items: flex-end;
}

.basket__listContainer .listItem:last-of-type {
  margin-bottom: 0 !important;
}

.basket__listContainer::-webkit-scrollbar {
  width: 8px;
}

.basket__listContainer::-webkit-scrollbar-track {
  background: #2f2f37;
}

.basket__listContainer::-webkit-scrollbar-thumb {
  background: #a5a5d6;
}

.basket__listContainer::-webkit-scrollbar-thumb:hover {
  background: #8585ad;
}

.basket__container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.basket__totalContainer {
  display: flex;
  align-items: center;
}

.basket__totalContainer svg {
  width: 33px;
  height: 33px;
}
