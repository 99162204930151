.account {
  display: grid;
  grid-template-columns: 320px 100%;
  column-gap: 60px;
  margin-top: 120px;
}

.nav {
  padding: 0;
  margin: 0;
}

.contentBox {
  max-width: 850px;
  width: 100%;
}

.list {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  margin-top: 0;
}

.listItem {
  margin: 18px 0;
}

.link {
  transition: 0.2s;
  text-decoration: none;
}

.link:hover {
  cursor: pointer;
  opacity: 0.7;
}

.link_active {
  color: white;
}

.button {
  font-size: 24px;
  padding: 0;
  border: none;
  background-color: unset;
  transition: 0.2s;
}

.button:hover {
  cursor: pointer;
  opacity: 0.7;
}

.text {
  color: #8585ad;
}

.profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 480px;
}
