.orderHistory {
  max-height: 80vh;
  width: 100%;
}

.list {
  max-height: 77vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  list-style-type: none;
  padding: 0;
  margin: 0;
  overflow-y: scroll;
}

.profileList {
  composes: list;
  max-width: 100%;
  align-self: flex-start;
}

.list::-webkit-scrollbar {
  width: 8px;
}

.list::-webkit-scrollbar-track {
  background: #2f2f37;
}

.list::-webkit-scrollbar-thumb {
  background: #a5a5d6;
}

.list::-webkit-scrollbar-thumb:hover {
  background: #8585ad;
}

.listItem {
  width: 90%;
  background: #1c1c21;
  border-radius: 40px;
  padding: 24px;
  transition: 0.2s;
}

.listItem:hover {
  transform: scale(0.97);
}

.link {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: unset;
  transition: 0.2s;
}

.textBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dataBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ingrList {
  list-style-type: none;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0 0 20px;
}

.ingItem {
  position: relative;
  width: 64px;
  height: 64px;
  margin-left: -20px;
  background-color: #131316;
  border-radius: 50%;
  border: 3px solid #801ab2;
  overflow: hidden;
  z-index: 4;
}

.ingDigit {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  background-color: rgba(0, 0, 0, 0.3);
  width: 110%;
  height: 110%;
  z-index: 3;
  top: -5px;
}

.img {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.textContainer {
  display: flex;
  align-items: center;
}

.visible {
  display: block;
}

.hidden {
  display: none;
}
