.App {
  margin: 0 auto;
  font-family: "JetBrainsMono", Arial, sans-serif;
}

.componentContainer {
  max-width: 1240px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.centeredComponent {
  width: 100%;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #8585ad;
}

.logo {
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -1;
}
