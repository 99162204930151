.ingredient {
  text-decoration: none;
  color: white;
  position: relative;
  max-width: 256px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.2s;
}

.ingredient:hover {
  color: #6a6aff;
  cursor: pointer;
}

.ingredient__image {
  transition: 0.2s;
}

.ingredient:hover .ingredient__image {
  cursor: pointer;
  transform: scale(1.2);
}

.ingredient__priceBox {
  display: flex;
  align-items: center;
}

/* .ingredient__priceBox:hover {
  border: 1px solid skyblue;
} */

.ingredient__price {
  margin-right: 8px;
}

.ingredient__text {
  text-align: center;
}
