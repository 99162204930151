.contentBox {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: unset;
  border-radius: 40px;
  margin: 120px auto 0;
}

.title {
  margin: 0;
}

.title_shadow {
  composes: title;
  text-shadow: 0px 0px 16px rgba(51, 51, 255, 0.25),
    0px 0px 8px rgba(51, 51, 255, 0.25), 0px 4px 32px rgba(51, 51, 255, 0.5);
}

.title_modified {
  composes: title;
  margin: 20px 0;
  align-self: flex-start;
}

.image {
  width: 120px;
  height: auto;
}

.ingImage {
  width: 520px;
  height: 240px;
}

.textContainer {
  text-align: center;
}

.statsList {
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-width: 520px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.statsListItem {
  max-width: 23%;
  text-align: center;
}
